import React from 'react'

import SEO from '../components/SEO'
import Hero from '../components/Hero'

export default function NotFoundPage() {
  return (
    <>
      <SEO title="Fant ikke siden" />
      <Hero type="center" title="Fant ikke siden du leter etter" />
    </>
  )
}
